import React from "react";
import ClientsComponent from "./ClientsComponent";
import LocationMap from "./LocationMap";
import ReactGA from "react-ga";

class ContactoComponent extends React.Component{

    render() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        return(
            <div className="bg-abp-dark-blue row-no-margin">
                <div className="container justify-content-center  fixed-size-column">
                    <div className="row" style={{marginTop: "25px"}}>
                        <div className="col-lg-6 col-sm-12 center-horizontally ubicacion-text">
                            <h2>Nos encontramos en</h2>
                            <div style={{paddingBottom: "30px"}}>
                                <p> Av. Vergara 1989 B1688GEK Villa Tesei Hurlingham. </p>
                                <p> Pcia Buenos Aires. </p>

                            </div>
                            <div style={{paddingBottom: "30px"}}>
                                <h2>Contactos</h2>
                                <p>Tel: 11-77122617 / 11-4450-2516 / 11-4450-7089 </p>
                                <a href="mailto:info@abampere.com.ar">info@abampere.com.ar<br></br></a>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12" style={{paddingBottom: "100px"}}>
                            <LocationMap height={"400px"}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactoComponent;
