import React from "react";
import "../App.css"

class CardItemComponent extends React.Component{

    render(){
        return(
            <div className="card-item col-lg-5 col-md-5 col-sm-10">
                <div className="row-no-margin">
                    <div className="col-3">
                        <img className="card-icon" src={this.props.img}/>
                    </div>
                    <div className="col-9 content-divider white-text font-weight-bold">
                        { this.props.text}
                    </div>
                </div>
            </div>
        )
    }
}

export default CardItemComponent;

