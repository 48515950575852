import React from "react";
import CarouselPage from "./CarouselPage";
import foto1 from "../img/nosotros/1.jpg";
import foto2 from "../img/nosotros/2.jpg";
import foto3 from "../img/nosotros/3.jpg";
import foto4 from "../img/nosotros/4.jpg";
import foto5 from "../img/nosotros/5.jpg";
import foto6 from "../img/nosotros/6.jpg";
import schneider2 from "../img/nosotros/EcoXpert-Substation-Automation-Badge_white-backgorund_130218-300x300.png"
import schneider1 from "../img/nosotros/28059311_1777436582306991_4649054911981803618_n.png"
import vancouver1 from "../img/nosotros/vancouver1.png"
import vancouver2 from "../img/nosotros/vancouver2.jpg"
import premio from "../img/nosotros/premio.png"
import premioHongKong from "../img/nosotros/premio_hong_kong.png"
import mapaIntegradores from "../img/nosotros/7.png"
import ReactGA from 'react-ga';

class NosotrosComponent extends React.Component{

    images = [
        {id: 1, src: foto1},
        {id: 2, src: foto2},
        {id: 3, src: foto3},
        {id: 4, src: foto4},
        {id: 5, src: foto5},
        {id: 6, src: foto6},
    ];

    render(){
        ReactGA.pageview(window.location.pathname + window.location.search);
        return(
            <div>
                <CarouselPage images={this.images}></CarouselPage>
                <div className="row justify-content-center"
                     style={{marginRight: "0px", marginLeft: "0px", backgroundColor: "#413b6b"}}>

                    <div className="row content-divider">
                        <div className="flex-column">
                            <h1 className="white-text"> Nosotros </h1>
                        </div>
                    </div>
                </div>
                <div className="row-no-margin justify-content-center" style={{
                    paddingTop: "50px",
                    paddingBottom: "50px",
                    fontSize: "18px"
                }}>
                    <div className="fixed-size-column">
                        <b>Fundada en 2008 como Integrador de Sistemas de Schneider Electric</b>
                        <ul>
                            <li>
                                Se inició como una empresa de venta de medidores de energía.
                            </li>
                            <li>
                                Se incorporó inmediatamente a la implementación de sistemas de monitoreo de energía.
                            </li>
                            <li>
                                Años después se enorgullece de ofrecer soluciones de gestión de energía y calidad de energía a las principales empresas de Argentina.
                            </li>
                            <li>
                                Obtuvo su categoría Critical Power MASTER en el año 2016 participando del 1er congreso en Victoria, Canadá.
                            </li>
                            <li>
                                Los ingenieros altamente capacitados y experimentados de Abampere brindan hoy en dia soluciones energéticas eficientes respaldadas por la mejor tecnología disponible en el mercado.
                            </li>
                            <li>
                                Actualmente formamos parte referente en Argentina de la línea de expertise de la división de DIGITAL ENERGY
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row-no-margin justify-content-center bg-white content-divider" >
                    <div className="fixed-size-column">
                        <div className="row-no-margin">
                            <div className="col-lg-6">
                                <div className="row-no-margin text-center">
                                    <h5>Somos un Integrador Ecoxpert Critical Power Master avalados por Schneider
                                        Electric.</h5>
                                    <img src={schneider1} className="m-auto" style={{maxHeight: 200}}/></div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row-no-margin text-center">
                                    <h5>A partir de 2019 estamos en el programa de Ecoxpert Substation Automation</h5>
                                    <img src={schneider2} className="m-auto" style={{maxHeight: 200}}/></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-no-margin justify-content-center bg-white content-divider" >
                    <div className="fixed-size-column">
                        <div className="row-no-margin">
                            <h5>Cada año Schneider Electric Argentina y Global elige a sus Integradores que categorizan
                                como MASTER LEVEL en Critical Power por</h5>
                            <ul>
                                <li>
                                    Haber completado amplios requisitos de capacitación y certificación.
                                </li>
                                <li>
                                    Demostrada excelencia más allá de lo esperado por el canal.
                                </li>
                                <li>
                                    Participar en los paneles internacionales con los desarrolladores de Software y Hardware de Schneider Electric. Estrecha vinculación.
                                </li>
                                <li>
                                    Colaboración con los equipos de investigación y desarrollo de Schneider Electric.
                                </li>
                                <li>
                                    Desarrollar soluciones verdaderamente innovadoras utilizando EcoStruxure Power.
                                </li>
                                <li>
                                    Alta Experiencia de los Ingenieros.
                                </li>
                                <li>
                                    Cantidad de clientes importantes en la Industria Argentina.
                                </li>
                                <li>
                                    Premios obtenidos en los congresos Master global por proyectos de Innovación Tecnológica.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row-no-margin justify-content-center bg-white content-divider" >
                    <h4> Mapa de integradores a nivel mundial</h4>
                </div>
                <div className="row-no-margin justify-content-center bg-white content-divider" >
                    <img src={mapaIntegradores} />
                </div>
                <div className="row-no-margin justify-content-center bg-white content-divider" >
                    <h4>PARTICIPACION DEL EVENTO ECOXPERT MASTER EN VANCOUVER, CANADA EN 2018</h4>
                </div>
                <div className="row-no-margin justify-content-center bg-white content-divider" >
                    <div className="fixed-size-column">
                        <div className="row-no-margin">
                            <div className="col-lg-6">
                                <div className="row-no-margin text-center">
                                    <img src={vancouver1} className="m-auto" style={{maxHeight: 300}}/></div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row-no-margin text-center">
                                    <img src={vancouver2} className="m-auto" style={{maxHeight: 300}}/></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-no-margin justify-content-center bg-white content-divider" >
                    <div className="fixed-size-column">
                        <div className="row-no-margin">
                            <div className="col-lg-3 ">
                                <img src={premio} className="float-right img-fluid" style={{maxHeight: 400}}/>
                            </div>
                            <div className="col-lg-6">
                                <div className="row-no-margin">
                                    <h5 style={{paddingLeft: 0}}>En Vancouver Ganamos el premio de Technical Innovation MASTER</h5>
                                </div>
                                <div className="row-no-margin justify-content-end text-right">
                                    <h5 className="" style={{paddingTop:275}}>Participación en el evento Ecoxpert master en Hong Kong, donde ganamos un premio de Technical Innovation.</h5>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <img src={premioHongKong} className="m-auto img-fluid" style={{maxHeight: 400}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NosotrosComponent;
