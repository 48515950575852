import React from "react";
import "../css/CalidadDeEnergia.css"
import image1 from "../img/products/imgcalidaddeenergia.png";
import image2 from "../img/products/998-20734276_Social-05_1200x630.jpg"
import image3 from "../img/products/energy-management-systems.jpg"
import image4 from "../img/products/image9.png"
import ReactGA from "react-ga";

class GestionDeEnergiaComponent extends React.Component{

    render(){
        ReactGA.pageview(window.location.pathname + window.location.search);
        return(
            <div>
                <div className="row-no-margin justify-content-center"
                     style={{marginRight: "0px", marginLeft: "0px", backgroundColor: "#413b6b"}}>
                    <div className="row-no-margin" style={{ backgroundImage: `url(${image1})`,
                        width: "100%",
                        height: "400px",
                        backgroundSize: "cover",
                        backgroundPosition: "center center"}}>

                    </div>

                </div>
                <div className="row-no-margin justify-content-center bg-abp-extra-dark-blue text-center" style={{paddingTop: "20px", paddingBottom: "20px"}}>
                    <div className="col-lg-8">
                        <h1 className="white-text"> Gestion de Energía </h1>
                    </div>
                </div>

                <div className="row-no-margin content-divider justify-content-center text-center">
                    <div className="fixed-size-column">
                        <h3> Monitoree, controle y optimice la eficiencia de sus sistemas eléctricos y
                            no-eléctricos. </h3>
                        <p>
                            ABAMPERE ofrece un sistema completo de gestión de energía para proyectos de cualquier tamaño.
                            Todos los sistemas de gestión de energía están construidos según las especificaciones del cliente
                            y garantizan el cumplimiento de todas las normas y reglamentaciones aplicables.
                        </p>

                    </div>
                </div>

                <div className="row-no-margin justify-content-center bg-abp-dark-blue " style={{marginBottom: 30}} >

                    <div className="fixed-size-column">
                        <div className="row-no-margin ">
                            <div className="col-lg-4 ">
                                <img className="img-fluid" src={image2}></img>
                            </div>
                            <div className="col-lg-8 content-divider white-text">
                                <h3 className="text-center"> Sistemas de gestión de energía</h3>
                                Un sistema de gestión de energía conducirá a una productividad mejorada y una reducción en los costos de energía.
                                Los sistemas de gestión de energía de ABAMPERE se pueden integrar completamente con una gama de hardware de terceros
                                para proporcionar una visión general completa del sistema.
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row-no-margin justify-content-center bg-abp-light-blue " style={{marginBottom: 30}} >
                    <div className="fixed-size-column">
                        <div className="row-no-margin ">
                            <div className="col-lg-8 col-md-6 col-sm-6  content-divider white-text">
                                <h3 className="text-center"> S.G.E. </h3>
                                Un SGE monitorea y analiza el flujo de variables de un sistema eléctrico y no eléctrico.
                                El SGE de ABAMPERE proporciona los datos para aumentar la eficiencia operativa mientras
                                se cumplen los objetivos de ahorro de energía y se reducen los costos. El SGE está diseñado
                                para su uso en instalaciones de servicios públicos, comerciales e industriales.
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 ">
                                <img className="img-fluid" src={image3}/>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row-no-margin justify-content-center bg-abp-dark-blue " style={{marginBottom: 30}} >
                    <div className="fixed-size-column">
                        <div className="row-no-margin ">
                            <div className="col-lg-4 ">
                                <img className="img-fluid" src={image4} />
                            </div>
                            <div className="col-lg-8 content-divider white-text">
                                <h3 className="text-center"> Scada P.S.O </h3>
                                Supervisory Control and Data Acquisition:
                                es un sistema de control y monitoreo altamente confiable que opera enviando señales codificadas
                                a través de la red de comunicación de una instalación. El SCADA de ABAMPERE se utiliza junto
                                con PLCs o RTUs para ofrecer capacidades de monitoreo de todo el sistema junto con el control
                                de interruptores automáticos y muchos otros dispositivos.

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default GestionDeEnergiaComponent;
