import React from "react";
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView} from "mdbreact";

import '../App.css'


class CarouselPage extends React.Component{

    makeCarouselItem() {
        return this.props.images.map((each) => (
            <MDBCarouselItem key={each.id} itemId={ each.id }>
                <MDBView >
                    <img
                        className="d-block w-100"
                        style={{ objectFit: "contain"}}
                        src={ each.src }
                        alt={"Imagen " + each.id}
                    />
                </MDBView>
            </MDBCarouselItem>
        ))

    }

    render(){
        return (
            <MDBCarousel
                activeItem={1}
                length={this.props.images.length}
                showControls={true}
                showIndicators={true}
                className="z-depth-1 bg-dark"
            >
                <MDBCarouselInner>
                    {this.makeCarouselItem()}
                </MDBCarouselInner>
            </MDBCarousel>
        );
    }
}

export default CarouselPage;
