import React from 'react'
import '../css/NavbarButton.css'
import {NavDropdown} from "react-bootstrap";

class Navbar extends React.Component{

    state = {
        navbarItems: [
            { id: 1, title: "Inicio", link: "../", pathname: "/"},
            { id: 7, title: "Nosotros", link: "../nosotros" , pathname: "/nosotros"},

            // { id: 2, title: "Medidores", link: "../medidores" , pathname: "/medidores", isDropdown: true, dropdownOptions: [{id:1, name: "PM 8000", link:"PM 8000"}, {id:1, name: "medidor2", link:"medidor2"},{id:1, name: "medidor3", link: "medidor3"}]},
            { id: 2, title: "Calidad de energía", link: "../calidad-de-energia" , pathname: "/calidad-de-energia"},
            { id: 3, title: "Gestión de energía", link: "../gestion-de-energia" , pathname: "/gestion-de-energia"},
            { id: 4, title: "Eficiencia energética", link: "../eficiencia-energetica" , pathname: "/eficiencia-energetica"},
            { id: 5, title: "Casos de éxito", link: "../casos-de-exito" , pathname: "/casos-de-exito"},
            { id: 6, title: "SMEC", link: "../smec" , pathname: "/smec"},
            { id: 8, title: "Contacto", link: "../contacto" , pathname: "/contacto"},
            { id: 9, title: "Cursos", link: "../cursos" , pathname: "/cursos"},
        ]
    };

    createButtonJSX() {
        const {pathname}= window.location;
        return this.state.navbarItems.map((each) => {
            if (!each.isDropdown) {
                return(
                    <li key={each.id} className={"nav-item " + (pathname === each.pathname ? "active" : "")}>
                        <a className="nav-link" href={each.link}> {each.title} </a>
                    </li>
                )
            } else {
                return (
                    <NavDropdown title={each.title} id="basic-nav-dropdown">
                        { each.dropdownOptions.map((option) => (
                            <NavDropdown.Item href={each.link + "/" + option.link} key={option.key}>{option.name}</NavDropdown.Item>
                        )) }
                    </NavDropdown>
                )
            }})
    };


    render() {
        return(
            <nav className="navbar navbar-expand-sm bg-abp-extra-dark-blue"  >
                <div className="navbar-collapse" id="navbarNav" >
                    <ul className="navbar-nav nav-fill w-75 center-horizontally" >
                        {this.createButtonJSX()}
                    </ul>
                </div>
            </nav>
        )
    }
}

export default Navbar;
