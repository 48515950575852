import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import './App.css';
import {BrowserRouter, Route, Switch} from "react-router-dom"
import Navbar from './components/Navbar';
import CarouselPage from "./components/CarouselPage";
import CalidadDeEnergiaComponent from "./components/CalidadDeEnergiaComponent";
import HomeComponent from "./components/HomeComponent";
import NosotrosComponent from "./components/NosotrosComponent"
import LocationComponent from "./components/LocationComponent";
import FooterComponent from "./components/FooterComponent";
import abpLogo from "./img/logo abp hd.png"
import ecoxpertLogo from "./img/EcoXpert_Base_logo_PNG_RGB_291019.png"
import GestionDeEnergiaComponent from "./components/GestionDeEnergiaComponent";
import CasosDeExitoComponent from "./components/CasosDeExitoComponent";
import SmecComponent from "./components/SmecComponent";
import EficienciaEnergeticaComponent from "./components/EficienciaEnergeticaComponent";
import ContactoComponent from "./components/ContactoComponent";
import CursosComponent from "./components/CursosComponent";
import ReactGA from 'react-ga';


class App extends React.Component {

    render() {
        ReactGA.initialize('UA-195263655-1');
        return (
            <div className="App" style={{fontFamily: "Montserrat, sans-serif"}}>
                {/*<div className="row-no-margin justify-content-center"  >*/}
                {/*    <div className="col-lg-10 col-md-12 d-none d-sm-block" >*/}
                {/*        <CarouselPage  />*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="row-no-margin justify-content-center">
                    <div className="fixed-size-column d-none d-lg-block d-xl-block " style={{height: "100px"}}>
                        <div className="row-no-margin">
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <img className="float-right" style={{height: "80px", marginTop: "10px", opacity: 0.8}}
                                     src={abpLogo}></img>
                            </div>
                            <div className="col-lg-8 col-md-4 my-auto text-center">
                                <h3 style={{paddingTop: 12, fontSize: "1.2em"}}>Somos líder en el gerenciamiento de la
                                    energía</h3>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 justify-content-end">
                                <img className="float-left" style={{height: "80px", marginTop: "10px", opacity: 0.8}}
                                     src={ecoxpertLogo}></img>
                            </div>
                        </div>
                    </div>
                    <div className="fixed-size-column d-none d-xl-none" style={{height: "100px"}}>
                        <div className="row-no-margin">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <img style={{height: "80px", marginTop: "10px", opacity: 0.8}} src={abpLogo}></img>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 justify-content-end">
                                <img style={{height: "80px", marginTop: "10px", opacity: 0.8}} src={ecoxpertLogo}></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row-no-margin justify-content-md-center">
                    <div className="w-100">
                        <Navbar/>
                    </div>
                </div>

                {/*Content*/}
                <div>
                    <BrowserRouter>
                        <Switch>
                            <Route path="/nosotros" component={NosotrosComponent}/>
                            <Route path="/calidad-de-energia" component={CalidadDeEnergiaComponent}/>
                            <Route path="/gestion-de-energia" component={GestionDeEnergiaComponent}/>
                            <Route path="/casos-de-exito" component={CasosDeExitoComponent}/>
                            <Route path="/ubicacion" component={LocationComponent}/>
                            <Route path="/smec" component={SmecComponent}/>
                            <Route path="/eficiencia-energetica" component={EficienciaEnergeticaComponent}/>
                            <Route path="/contacto" component={ContactoComponent}/>
                            <Route path="/cursos" component={CursosComponent}/>
                            <Route path="/" component={HomeComponent}/>
                            <Route component={HomeComponent}/>
                        </Switch>
                    </BrowserRouter>
                </div>

                {/*Footer*/}
                <FooterComponent/>

            </div>
        );
    }
}

export default App;
