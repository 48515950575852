import React from "react";
import "../App.css"
import icon1 from "../img/eficiencia-energetica/248089.svg"
import icon2 from "../img/eficiencia-energetica/841805_energy_512x512.png"
import icon3 from "../img/eficiencia-energetica/841819_man_512x512.png"
import icon4 from "../img/eficiencia-energetica/big_data1.png"
import icon5 from "../img/eficiencia-energetica/big_data2.png"
import iso50001 from "../img/eficiencia-energetica/iso500.png"
import medir from "../img/eficiencia-energetica/MEDIR.png"
import image3 from "../img/eficiencia-energetica/image3.png"
import CardItemComponent from "./CardItemComponent.js";
import img8 from "../img/eficiencia-energetica/image8.png";
import CarouselPage from "./CarouselPage";
import img6 from "../img/eficiencia-energetica/fotos/image6.jpg"

import foto1 from "../img/eficiencia-energetica/fotos/1.jpg"
import foto2 from "../img/eficiencia-energetica/fotos/2.jpg"
import foto3 from "../img/eficiencia-energetica/fotos/3.jpg"
import foto4 from "../img/eficiencia-energetica/fotos/4.jpg"
import foto5 from "../img/eficiencia-energetica/fotos/5.jpg"
import foto6 from "../img/eficiencia-energetica/fotos/6.jpg"
import foto7 from "../img/eficiencia-energetica/fotos/7.jpg"
import foto8 from "../img/eficiencia-energetica/fotos/8.jpg"
import foto9 from "../img/eficiencia-energetica/fotos/9.jpg"
import foto10 from "../img/eficiencia-energetica/fotos/10.jpg"
import foto11 from "../img/eficiencia-energetica/fotos/11.jpg"
import ReactGA from "react-ga";


class EficienciaEnergeticaComponent extends React.Component{

    cards = [
        {id: 1, src: icon1, text: "Medición de energía con dispositivos comunicables. (Digitalización)"},
        {id: 2, src: icon2, text: "Medición de calidad de energía comunicables.  (Digitalización)"},
        {id: 2, src: icon3, text: "Medición de variables no eléctricas comunicables.  (Digitalización)"},
        {id: 2, src: icon4, text: "Ingeniería para la integración de las variables a la red de datos. (Digitalizar la Big Data)"},
        {id: 2, src: icon5, text: "Software de monitoreo de energía con capacidad de historizado. (Capacidad de contextualizar la Big Data)"},
    ];

    images = [
        {id: 1, src: foto1},
        {id: 2, src: foto2},
        {id: 3, src: foto3},
        {id: 4, src: foto4},
        {id: 5, src: foto5},
        {id: 6, src: foto6},
        {id: 7, src: foto7},
        {id: 8, src: foto8},
        {id: 9, src: foto9},
        {id: 10, src: foto10},
        {id: 11, src: foto11},
    ];

    render(){
        ReactGA.pageview(window.location.pathname + window.location.search);
        return(
            <div>
                <div className="row-no-margin justify-content-center bg-abp-dark-blue white-text" >
                    <div className="fixed-size-column content-divider" style={{fontColor: "white"}}>
                        <h6> <i> "Lo que no se define no se puede medir. Lo que no se mide, no se puede mejorar. Lo que no se mejora, se degrada siempre"</i> <br/> Lord Kelvin </h6>
                    </div>
                </div>
                <div className="row-no-margin justify-content-center bg-abp-dark-blue white-text" >
                    <div className="fixed-size-column content-divider" style={{fontColor: "white"}}>
                        <h5> Abampere es una compañía que se enorgullece de ser un referente líder en el mercado argentino en implementaciones como: </h5>
                    </div>
                </div>
                <CarouselPage images={this.images}></CarouselPage>
                <div className="row-no-margin justify-content-center" style={{marginBottom: 10}}>
                    {/*<div className="fixed-size-column row-no-margin">*/}
                    {/*    {*/}
                    {/*        this.cards.map((each) => (<CardItemComponent img={each.src} text={each.text}/>))*/}
                    {/*    }*/}
                    {/*</div>*/}
                    <img src={medir} className="img-fluid"/>
                </div>
                <div className="row-no-margin justify-content-center bg-abp-light-blue white-text" style={{marginBottom: 30}}>
                    <div className="fixed-size-column row-no-margin ">
                        <p className="content-divider">
                            En búsqueda de la eficiencia, el ahorro energético y la sostenibilidad de las organizaciones, ABAMPERE busca brindar el servicio y ayudar a interpretar los
                            datos que proporciona el sistema de Monitoreo de Energía avanzado que por más de 10 años venimos implementando en Argentina.
                        </p>
                    </div>
                </div>


                <div className="row-no-margin justify-content-center bg-abp-dark-blue" style={{marginBottom: 30}}>
                    <div className="fixed-size-column">
                        <div className="row-no-margin">
                            <div className="col-lg-2 col-md-4 col-sm-4 white-text" >
                                <img className="img-fluid" src={iso50001}/>
                            </div>
                            <div className="col-lg-8 col-md-7 col-sm-8 white-text content-divider">
                                <h3> ¿Cómo pensamos ayudar?</h3>
                                <b>Realizando una Auditoría Energética:</b> analizar y conocer el perfil de los usos de energía de un establecimiento, detectar oportunidades de
                                mejora y elaborar un informe técnico que contenga las recomendaciones de eficiencia energética, incluyendo una evaluación económica de las
                                mismas. Esto lo hacemos para clientes con base instalada de SGE o con clientes que no tienen un SGE funcional todavía.
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row-no-margin justify-content-center bg-abp-extra-dark-blue" style={{marginBottom: 30}}>
                    <div className="fixed-size-column">
                        <div className="row-no-margin">
                            <div className="col-lg-8 col-md-7 col-sm-12 white-text small-margin-bottom">
                                <h3> Política Energética </h3>
                                <p>Antes que nada, se debe definir la Política Energética dentro de una
                                    Organización.</p>
                                <br/>
                                <h3> Planificación energética</h3>
                                Plan energético, auditoria energética, identificar los principales consumos y su patrón de uso. Definir la línea de base de consumo de energía,
                                los indicadores de eficiencia energética (kWh/tn producto), los objetivos, las metas y los planes de acción priorizados económicamente.
                                <br/>
                                <h3> Implementación y operación:</h3>
                                ejecutar los proyectos de eficiencia energética.
                                <br/>
                                <h3> Verificación:</h3>
                                métodos y procedimientos para el
                                seguimiento y verificación de los ahorros de energía.
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 white-text" >
                                <img className="img-fluid" src={image3}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row-no-margin justify-content-center bg-abp-dark-blue" style={{marginBottom: 30}}>
                    <div className="fixed-size-column">
                        <div className="row-no-margin">

                            <div className="col-lg-6 col-md-6 col-sm-12 white-text content-divider">
                                <h3> Principales puntos a auditar:</h3>
                                <ul style={{paddingLeft: 30}}>
                                    <li>
                                        Sistemas de iluminación
                                    </li>
                                    <li>
                                        Motores eléctricos, variadores de velocidad.
                                    </li>
                                    <li>
                                        Sistemas de bombeo y ventilación.
                                    </li>
                                    <li>
                                        Sistemas de Aire comprimido
                                    </li>
                                    <li>
                                        Sistemas de Vapor
                                    </li>
                                    <li>
                                        Integración energética
                                    </li>
                                    <li>
                                        Sistemas de frio
                                    </li>
                                    <li>
                                        Sistemas de HVAC
                                    </li>
                                </ul>

                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 white-text imagen-with-min-size" style={{ backgroundImage: `url(${img8})`}}>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row-no-margin justify-content-center bg-abp-extra-dark-blue" style={{marginBottom: 30}}>
                    <div className="fixed-size-column">
                        <div className="row-no-margin">
                            <div className="col-lg-7 col-md-6 col-sm-12 white-text imagen-with-min-size" >
                                <img className="img-fluid" src={img6}/>
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12 white-text content-divider">
                                <h3> Lucro cesante de tu organización por problemas Calidad de Energía</h3>
                                <ul style={{paddingLeft: 30}}>
                                    <li>
                                        Estudios de calidad de energía según normativas IEEE519, IEC61000-4-30, EN50160
                                    </li>
                                    <li>
                                        Análisis de las perturbaciones SAG/SWELL y su direccionalidad, armónicos, flicker, desbalances.
                                    </li>
                                    <li>
                                        Soluciones posibles para mitigar estos fenómenos y buscar disminuir las paradas de planta inesperada
                                    </li>
                                </ul>
                                <p>
                                    Abampere buscara lograr la iniciativa en sus clientes para empezar en este camino en busca de la eficiencia energética y buscar soluciones
                                    a los problemas que ocasiona la calidad de la energía eléctrica en la línea productiva.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default EficienciaEnergeticaComponent;
