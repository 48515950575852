import React from "react";
import "../css/CalidadDeEnergia.css"
import vwLogo from "../img/vwlogo.png"
import psaPeugeotLogo from "../img/casos-de-exito-logos/psa-peugeot-citroen-logo-png-transparent.png"
import mercedesLogo from "../img/casos-de-exito-logos/mercedes-benz-logo.png"
import ferreroLogo from "../img/casos-de-exito-logos/ferrero-logo.png"
import cofcoLogo from "../img/casos-de-exito-logos/logo-cofco.png"
import seaboardLogo from "../img/casos-de-exito-logos/seaboard-logo-png-transparent.png"
import vwPanel from "../img/CASOS DE EXITO-FOTOS/panel-vw.png"
import pfizerLogo from "../img/casos-de-exito-logos/Pfizer_logo.png"
import peugeotPanel from "../img/CASOS DE EXITO-FOTOS/panel-2.png"
import mercedesPanel from "../img/CASOS DE EXITO-FOTOS/panel-mercedes.png"
import ferreroPanel from "../img/CASOS DE EXITO-FOTOS/panel-ferrero.png"
import cofcoPanel from "../img/CASOS DE EXITO-FOTOS/panel-cofco.png"
import seaboardPanel from "../img/CASOS DE EXITO-FOTOS/panel-seaboard.png"

import CarouselPage from "./CarouselPage";
import CasoDeExitoIndividual from "./CasoDeExitoIndividual";
import img1 from "../img/CASOS DE EXITO-FOTOS/20180316_131537.jpg";
import img2 from "../img/CASOS DE EXITO-FOTOS/20180316_131547.jpg";
import img3 from "../img/CASOS DE EXITO-FOTOS/20180316_131600.jpg";
import img4 from "../img/CASOS DE EXITO-FOTOS/20190417_164049.jpg";
import img5 from "../img/CASOS DE EXITO-FOTOS/20190417_164126.jpg";
import img6 from "../img/CASOS DE EXITO-FOTOS/20190418_100532.jpg";
import img7 from "../img/CASOS DE EXITO-FOTOS/20200519_095208.jpg";
import img8 from "../img/CASOS DE EXITO-FOTOS/20200715_173627.jpg";
import ReactGA from "react-ga";

class CasosDeExitoComponent extends React.Component{

    images = [
        {id: 1, src: img1},
        {id: 2, src: img2},
        {id: 3, src: img3},
        {id: 4, src: img4},
        {id: 5, src: img5},
        {id: 6, src: img6},
        {id: 7, src: img7},
        {id: 8, src: img8},
    ];

    state = {
        exitos: [
            {img: vwLogo, title: "Volkswagen", panel: vwPanel , text: "Se implementó el Sistema de Gestión de Energía. Se integra la medición de multivariables. Se desarrolla toda " +
                    "la visualización para la interpretación de datos. Dashboards especiales de Energy Analysis y reportes por modelado energético. KPI de unidades producidas."},

            {img: pfizerLogo, title: "Pfizer", text: "Un líder en en la industria farmacéutica confía en Abampere para su sistema de Gestión eficiente de " +
                    "la energía, implementando el software de monitoreo y la medición de multivariables eléctricas, aire comprimido, gas y agua de sus procesos productivos."},

            {img: mercedesLogo, title: "Mercedes-Benz", text: "Se implementó el Sistema de Gestión de Energía. Se integran variables de energía eléctrica de todas las subestaciones " +
                    "de planta. Se integran mediciones de gas, agua, aire y punto de rocío, GLP, efluentes."},

            {img: ferreroLogo, title: "Ferrero Argentina", text: "Se implementa el Sistema de monitoreo de energía  integrando variables eléctricas. Se proveen e instalan todos los" +
                    " medidores en las salas eléctricas. Se integra la medición de caudal de Agua de servicios y potable. Se automatiza el llenado de tanques de combustible de los " +
                    "grupos electrógenos y se integra al software."},

            {img: cofcoLogo, title: "COFCO", text: "Se puso en servicio el software de monitoreo de energía donde se integran las variables de índole eléctrica y los" +
                    " estados de los interruptores de las subestaciones. Se le da un uso muy importante para la operación de la turbina de co-generación."},

            {img: seaboardLogo, title: "SEABOARD", text: "La planta esta ubicada en la provincia de Salta en la localidad de ORAN. Es un ingenio azucarero que se dedica a producción de" +
                    " derivados de la caña e azúcar y a la producción de energía por Co-Generación. Se puso en servicio el software de gestión de energía y se cambiaron los 3 taleros SMEC " +
                    "para medición de energía cogenerada, exportada importada y entrada de planta."},
        ]

    };


    createCasosDeExito() {
        return this.state.exitos.map((each) => (
            <div className="col-lg-4 col-md-4 col-sm 6" style={{ border: "solid", borderColor: "#efefef"}}>
                <CasoDeExitoIndividual img={each.img} title={each.title}
                                       text={each.text} panel={each.panel}>
                </CasoDeExitoIndividual>
            </div>
        ));
    }

    render(){
        ReactGA.pageview(window.location.pathname + window.location.search);
        return(
            <div>
                <CarouselPage images={this.images}/>
                <div
                    className="row-no-margin justify-content-center content-divider text-white bg-abp-dark-blue font-weight-bold">
                    <h3> Casos de éxito</h3>
                </div>
                <div className="row-no-margin justify-content-center bg-light content-divider">
                    <div className="fixed-size-column">
                        <div className="row-no-margin">
                            { this.createCasosDeExito() }
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default CasosDeExitoComponent;
