import React from "react";
import "../css/CalidadDeEnergia.css"
import image1 from "../img/products/998-20734276_Social-02_1200x630.jpg";
import ion900img from "../img/products/ion9000schneider.png"
import acusineFiltersImg from "../img/products/electricalenclosure.JPG"
import accusineimg from "../img/products/accusine.jpg"
import videoPMS from "../img/videos/PM8000series_teaser_Espanol.m4v";
import ReactGA from 'react-ga';

class CalidadDeEnergiaComponent extends React.Component {

    render() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        return (
            <div>
                <div className="row-no-margin justify-content-center video-container"
                     style={{marginRight: "0px", marginLeft: "0px"}}>
                    <div style={{
                        backgroundImage: `url(${image1})`,
                        width: "100%",
                        height: "400px",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        marginBottom: "10px"
                    }}>
                        <video autoPlay muted loop>
                            <source src={videoPMS} type="video/mp4"/>
                        </video>
                    </div>
                </div>
                <div className="row-no-margin bg-abp-dark-blue justify-content-center"
                     style={{paddingTop: "20px", paddingBottom: "20px"}}>
                    <div className="flex-column">
                        <h1 className="white-text"> Calidad de Energía </h1>
                    </div>
                </div>
                <div className="row-no-margin justify-content-center "
                     style={{backgroundColor: "white", paddingTop: "70px", paddingBottom: "70px"}}>
                    <div className="fixed-size-column">
                        <div className="row-no-margin">
                            <div className="col-lg-6">
                                <h3 className="font-weight-bold">
                                    ¿Cuánto le cuestan los problemas de calidad de energía?
                                </h3>
                                <p className="black-text">

                                    Las cargas electrónicas sensibles, los motores y los variadores de velocidad
                                    contribuyen
                                    a los problemas de calidad de la energía.
                                    De hecho, los problemas relacionados con la calidad de la energía son comunes y una
                                    causa importante de tiempo de inactividad no programado.
                                    Puede estar perdiendo mucho dinero y productividad sin saberlo.<br/> Los beneficios
                                    de la
                                    corrección de la calidad de la energía son duraderos y mejoran sus operaciones y sus
                                    resultados.

                                </p>
                            </div>
                            <div className="col-lg-6">
                                <h3 className="font-weight-bold">
                                    Conozca las soluciones de ABAMPERE para:
                                </h3>
                                <ul>
                                    <li>
                                        Detectar los problemas
                                    </li>
                                    <li>
                                        Corregir los problemas.
                                    </li>
                                </ul>
                                <p>

                                    En detección de problemas, ABAMPERE ofrece una gama de medidores, analizadores de
                                    red capaz de cumplir con las estadares mas exigentes, de la mano de Schneider
                                    electric
                                    le proporcionamos el PowerLogic ION9000 es el medidor de calidad de energía más
                                    innovador, avanzado y preciso del mundo.

                                    La precisión 0.1S certificada por terceros, el diseño modular, la capacidad de
                                    programación ION flexible y el análisis avanzado de calidad de energía lo convierten
                                    en el medidor de potencia perfecto para instalaciones industriales o de atención
                                    médica, centros de datos y redes de servicios públicos.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-no-margin justify-content-center bg-abp-dark-blue">
                    <div className="fixed-size-column">
                        <div className="row-no-margin">
                            <div className="col-lg-8  col-md-7 col-sm-12 white-text content-divider">

                                <h3>FILTRO ACTIVO AccuSine PCS+</h3>

                                La solución de ABAMPERE para el filtrado de armónicos activos.

                                AccuSine PCS + es una solución de calidad de energía flexible, de alto rendimiento y
                                rentable que estabiliza las redes eléctricas
                                proporcionando mitigación armónicas, corrección del factor de potencia y equilibrio
                                de
                                carga utilizando la ultima tecnología de Schneider Electric en hardware basado en
                                IGBTs
                                de potencia y un potente algoritmo.


                                Otra solución de ABAMPERE pensada para edificios:

                                La serie AccuSine PCSn (Tetrapolar), es una solución de filtrado de armónicos
                                activos
                                escalable y flexible de alto rendimiento que brinda confiabilidad y eficiencia a su
                                sistema eléctrico, lo que resulta en un mayor tiempo de actividad, una mayor
                                eficiencia
                                operativa, una vida útil prolongada del equipo y una mayor eficiencia energética.
                            </div>
                            <div className="col-lg-4 col-md-5 col-sm-12 white-text imagen-with-min-size"
                                 style={{backgroundImage: `url(${acusineFiltersImg})`}}>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="content-divider">
                </div>
                <div className="row-no-margin justify-content-center bg-abp-light-blue" style={{marginBottom: 30}}>
                    <div className="fixed-size-column">
                        <div className="row-no-margin">
                            <div className="col-lg-4 col-md-5 col-sm-12 white-text imagen-with-min-size"
                                 style={{backgroundImage: `url(${ion900img})`}}>
                            </div>
                            <div className="col-lg-8 col-md-7 col-sm-12 white-text content-divider">
                                <h3> PowerLogic ION9000 </h3>
                                PowerLogic ION9000 es el medidor de calidad de energía más innovador, avanzado y preciso
                                del mundo.

                                La precisión 0.1S certificada por terceros, el diseño modular, la capacidad de
                                programación ION flexible
                                y el análisis avanzado de calidad de energía lo convierten en el medidor de potencia
                                perfecto para instalaciones
                                industriales o de atención médica, centros de datos y redes de servicios públicos.

                            </div>

                        </div>
                    </div>

                </div>

                <div className="row-no-margin justify-content-center bg-abp-dark-blue">
                    <div className="fixed-size-column">
                        <div className="row-no-margin">
                            <div className="col-lg-8 col-md-7 col-sm-12 white-text content-divider">
                                <h3> AccuSine PCSn </h3>
                                La serie AccuSine PCSn (Tetrapolar), es una solución de filtrado de armónicos activos
                                escalable y
                                flexible de alto rendimiento que brinda confiabilidad y eficiencia a su sistema
                                eléctrico, lo que
                                resulta en un mayor tiempo de actividad, una mayor eficiencia operativa, una vida útil
                                prolongada
                                del equipo y una mayor eficiencia energética.
                            </div>
                            <div className="col-lg-4 col-md-5 col-sm-12 white-text imagen-with-min-size"
                                 style={{backgroundImage: `url(${accusineimg})`}}>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

export default CalidadDeEnergiaComponent;
