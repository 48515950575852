import React from "react";

class LocationMap extends React.Component {

    render() {
        return (
            <iframe width="100%" height={this.props.height ? this.props.height : "600px"} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3283.3877642542434!2d-58.6358747!3d-34.61964!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe79a629081603a56!2sABAMPERE%20S.A.!5e0!3m2!1sen!2sar!4v1581514879262!5m2!1sen!2sar"
            ></iframe>
        )
    }
}

export default LocationMap;