import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import nestleSvg from "../img/svg/Nestlé.svg";
import accentureSvg from "../img/svg/Accenture.svg";
import bridgestoneSvg from "../img/svg/Bridgestone_logo.svg";
import quilmesSvg from "../img/svg/Quilmes.svg";

import abinbev from "../img/logos_clientes/abinbev.png";
import aaarg from "../img/logos_clientes/aeropuertos_arg.png";
import aluar from "../img/logos_clientes/aluar.png";
import andina from "../img/logos_clientes/andina.png";
import aysa from "../img/logos_clientes/aysa.png";
import cobra from "../img/logos_clientes/cobra.png";
import cofco from "../img/logos_clientes/cofco.png";
import danone from "../img/logos_clientes/danone.png";
import mercedesBenz from "../img/logos_clientes/mercedes.png"
import vwlogo from "../img/logos_clientes/vw.png"


const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 6,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 600 },
        items: 4,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    }
};

class ClientsComponent extends React.Component{
    state= {
        svg: [
            // {id:1, name: "Nestle", src: nestleSvg, link: "https://www.nestle.com.ar/"},
            // {id:2, name: "Accenture", src:accentureSvg},
            {id:3, name: "Volkswagen", src:vwlogo,},
            // {id:4, name: "Bridgestone", src:bridgestoneSvg},
            {id:5, name: "Quilmes", src:quilmesSvg},
            {id:6, name: "Mercedes-Benz", src: mercedesBenz},
            {id:7, name: "Abinbev", src:abinbev},
            {id:8, name: "Aeropuertos Agentina", src:aaarg},
            {id:9, name: "Aluar", src:aluar},
            {id:10, name: "Andina", src:andina},
            {id:11, name: "Aysa", src:aysa},
            {id:12, name: "Cobra", src:cobra},
            {id:13, name: "Cofco", src:cofco},
            {id:14, name: "Danone", src:danone},
        ]
    };

    makeCarouselItem() {
        return this.state.svg.map((each) => (
            <div className="d-block content-divider" key={each.id} >
                <a href={ each.link }>
                    <img src={ each.src }
                         alt={ each.name + " logo"}
                         style={
                             {
                                 width: (each.customWidth ? each.customWidth :"150px"),
                                 maxHeight: (each.customHeight ? each.customHeight :"150px")
                             }
                         }/>
                </a>
            </div>
        ))

    }

    render(){
        return(
            <div style={{ marginTop: "50px"}}>
                <div className="row-no-margin justify-content-center ">
                    <h3 className="small-title black"> Nuestros clientes </h3>
                </div>
                <Carousel className="row-no-margin m-auto"
                          swipeable={true}
                          draggable={true}
                          showDots={false}
                          responsive={responsive}
                          ssr={false} // means to render carousel on server-side.
                          infinite={true}
                          autoPlay={this.props.deviceType !== "mobile" ? true : false}
                          autoPlaySpeed={3000}
                          keyBoardControl={false}
                          customTransition="all .5"
                          transitionDuration={500}
                          removeArrowOnDeviceType={["tablet", "mobile"]}
                          deviceType={this.props.deviceType}
                          dotListClass="custom-dot-list-style"
                          itemClass="carousel-item-padding-40-px"
                            >
                    {this.makeCarouselItem()}
                </Carousel>
            </div>
        )
    }
}

export default ClientsComponent;
