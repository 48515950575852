import React from "react";
import LocationMap from "./LocationMap";
import '../App.css'
import ReactGA from "react-ga";

class LocationComponent extends React.Component {
    render() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        return(
            <div className="container justify-content-center col-lg-7 col-md-12 col-sm-12">
                <div className="row"  style={{ marginTop: "25px"}}>
                    <div className="col-lg-6 col-sm-12 center-horizontally ubicacion-text">
                        <h2>Nos encontramos en</h2>
                        <div style={{paddingBottom: "30px"}}>
                            <p>Av. Vergara 2011 B1688GEK Villa Tesei Hurlingham. </p>
                            <p> Pcia Buenos Aires. TE 4450 2516/7089 </p>
                        </div>
                        <div style={{paddingBottom: "30px"}}>
                            <h2>Contactos</h2>
                            <p>info@abampere.com.ar<br></br>
                                jml@abampere.com.ar </p></div>
                    </div>
                    <div className="col-lg-6 col-sm-12" style={{paddingBottom: "100px"}}>
                        <LocationMap height={"400px"}/>
                    </div>

                </div>

            </div>
        )
    }
}

export default LocationComponent;
