import React from "react";
import image1 from "../img/fondos/iStock-522466306.jpg";
import powerlogicION from "../img/products/powerlogic_ion8650.png";
import cammesa from "../img/products/cammesa.jpg";
import image3 from "../img/products/image3.png";
import panelSmec from "../img/products/panel_smec.png";
import accusineimg from "../img/products/accusine.jpg";
import CarouselPage from "./CarouselPage";
import img1 from "../img/fondos/iStock-522466306.jpg";
import img2 from "../img/fondos/foto2.jpg";
import img3 from "../img/fondos/foto1.png";
import img4 from "../img/fondos/foto4.jpeg";
import ReactGA from "react-ga";

class SmecComponent extends React.Component{

    images = [
        {id: 1, src: img1},
        {id: 2, src: img2},
        {id: 3, src: img3},
        {id: 4, src: img4},
    ];

 render() {
     ReactGA.pageview(window.location.pathname + window.location.search);
     return(
         <div>
             {/*<div className="row-no-margin justify-content-center video-container" style={{marginRight: "0px", marginLeft: "0px"}}>*/}
             {/*    <div style={{ backgroundImage: `url(${image1})`,*/}
             {/*        width: "100%",*/}
             {/*        height: "410px",*/}
             {/*        backgroundSize: "cover",*/}
             {/*        backgroundPosition: "center center"}}>*/}
             {/*    </div>*/}
             {/*</div>*/}
             <CarouselPage images={this.images}></CarouselPage>
             <div className="row-no-margin bg-abp-dark-blue justify-content-center" style={{paddingTop:"20px", paddingBottom: "20px"}}>
                 <div className="flex-column" >
                     <h1 className="white-text"> Medición SMEC </h1>
                 </div>
             </div>
             <div className="row-no-margin justify-content-center " style={{ backgroundColor: "white", paddingTop:"70px", paddingBottom: "70px"}}>
                 <div className="fixed-size-column">
                     <div className="row-no-margin">
                         <div className="col-lg-6">
                             <h3 className="font-weight-bold">
                                 Que es la medición SMEC:
                             </h3>
                             <p className="black-text">

                                 El Sistema de Medición Comercial (SMEC) se encarga de medir los intercambios de Energía Eléctrica entre los distintos Agentes del MEM
                                 (Mercado Electrico Mayorista). Dicha información determina la Generación aportada al sistema o la Demanda tomada del mismo por cada Agente.
                                 <ul style={{ marginTop: 7}}>
                                     <li>
                                         Puntos de Medición Smec en todo el país: 2416
                                     </li>
                                     <li>
                                         Medidores de DEMANDA: 1849
                                     </li>
                                     <li>
                                         Medidores de GENERACIÓN: 1256
                                     </li>
                                 </ul>
                                 Por otro lado, CAMMESA recibe y procesa todas las mediciones registradas por los distintos medidores del MEM, controlando la calidad de los
                                 datos y la disponibilidad de los mismos. La gestión del SMEC se completa mediante un equipo de auditores externos que controlan y verifican los puntos de medida a través de la realización de ensayos de campo.
                                 <br/><br/>Organismo a cargo:
                                 <img src={cammesa} className="img-fluid"/>
                             </p>
                         </div>
                         <div className="col-lg-6">
                             <h3 className="font-weight-bold">
                                 Medidores Homologados para SMEC
                             </h3>
                             <p>
                                 Cammesa homologa y certifica los medidores capaces de cumplir con las mas exigentes normativas y habilita los mismos para ser instalados en los
                                 NODOS de medición SMEC.
                                 El medidor homologado por excelencia es el ION8650 de Schneider Electric, en sus dos formatos:
                             </p>
                             <img src={powerlogicION} className="img-fluid"/>
                         </div>
                     </div>
                 </div>
             </div>
             <div className="row-no-margin justify-content-center bg-abp-dark-blue" >
                 <div className="fixed-size-column">
                     <div className="row-no-margin">
                         <div className="col-lg-8  col-md-7 col-sm-12 white-text content-divider">

                             <h3>TABLERO DE MEDICION SMEC:</h3>

                             Realizamos el tablero SMEC completo, con toda la normativa constructiva solicitada por Cammesa.
                             Los tipos de tableros para nodos se diferencian si los mismos están colocados en MT o AT y dependiendo de ello los mismos cuentan con
                             (medidor principal, medidor de control y respaldo) o con (medidor principal y de respaldo).

                         </div>
                         <div className="col-lg-4 col-md-5 col-sm-12 white-text imagen-with-min-size" style={{ backgroundImage: `url(${image3})`}}>
                         </div>
                     </div>
                 </div>

             </div>
             <div className="content-divider">
             </div>
             <div className="row-no-margin justify-content-center bg-abp-light-blue" style={{marginBottom: 30}}>
                 <div className="fixed-size-column">
                     <div className="row-no-margin">
                         <div className="col-lg-4 col-md-5 col-sm-12 white-text imagen-with-min-size" style={{ backgroundImage: `url(${panelSmec})`}}>
                         </div>
                         <div className="col-lg-8 col-md-7 col-sm-12 white-text content-divider">
                             <h3>GESTION DEL PROYECTO SMEC:</h3>
                             En cuanto a las instalaciones de medición, asesoramos a los Agentes del MEM, a nuestros clientes,  durante
                             todo el proceso de gestión del proyecto, cálculos de compensación de perdidas, programación del medidor, confección del formulario SMEC.
                             Entregamos el proyecto Técnico SMEC en mano para ser presentado ante Cammesa.
                         </div>
                     </div>
                 </div>
             </div>
         </div>
         )
 }
}

export default SmecComponent;
