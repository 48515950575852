import React from "react";
import ClientsComponent from "./ClientsComponent";
import image1 from "../img/products/998-20734276_Social-01_1200x630.jpg"
import linkedinLogo from "../img/LinkedIn_logo_initials.png"
import dataCenterImage from "../img/products/AdobeStock_350462469-scaled.jpeg"
import facebookLogo from "../img/facebook_circle-512.png"
import instagramLogo from "../img/instagramlogo.png"
import ecoxpertSquareLogo from "../img/EcoXpert_Critical Power_Master_v2_logo.png"
import "../css/HomeStyles.css"
import schneider720pvid from "../img/videos/schneider_electric_720p.mp4"
import ReactGA from "react-ga";

class HomeComponent extends React.Component{

    render(){
        ReactGA.pageview(window.location.pathname + window.location.search);
        return(
            <div>
                <div className="row-no-margin video-container"
                     style={{ backgroundImage: `url(${dataCenterImage})`,
                         width: "100%",
                         height: 400,
                         backgroundPosition: "center center",
                         display: "flex",
                         justifyContent: "center",
                         alignItems: "center"
                     }}>
                    <video autoPlay muted loop>
                        <source src={schneider720pvid} type="video/mp4"/>
                    </video>

                    <div className="col-12">
                        <div
                            className="row-no-margin justify-content-center content-divider text-white font-weight-bold">
                            <h3> Bienvenido a Abampere</h3>
                        </div>
                        <div className="row-no-margin justify-content-lg-start">
                            <div className="col-6">
                                <img src={facebookLogo} style={{height: 30, marginTop: 200, marginLeft: "2%"}}/>
                                <a target="_blank" href="https://www.instagram.com/abampere.sa/?hl=es-la">
                                    <img src={instagramLogo} style={{height: 30, marginTop: 200, marginLeft: 10}}/>
                                </a>
                                <a target="_blank" href="https://www.linkedin.com/company/abampere-s-a/?originalSubdomain=ar">
                                    <img src={linkedinLogo} style={{height: 30, marginTop: 200, marginLeft: 10}}/>
                                </a>
                            </div>

                            <div className="col-6 justify-content-end">
                                <img className="float-right" src={ecoxpertSquareLogo} style={{height: 100, marginTop: 150, marginBottom: 50, marginRight: "2%"}}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-no-margin justify-content-center content-divider bg-abp-dark-blue text-white font-weight-bold" >
                    <div className="fixed-size-column" >
                        <h3 style={{fontWeight: "700 !important"}}> Bienvenido a Abampere</h3>
                    </div>
                </div>

                <div className="row-no-margin justify-content-center bg-light content-divider">
                    <div className="fixed-size-column">
                        <div className="row-no-margin">
                            <div className="col-lg-4">
                                <h4>Sector industrial en Argentina</h4>
                                <p>
                                    ABAMPERE es el único integrador de sistemas con categoría MASTER en Argentina, avalado por Schneider Electric.
                                    Como principal integrador, proveemos productos y servicios para el gerenciamiento correcto de la energía y los sistemas de potencia críticos.
                                    ABAMPERE cuenta con clientes muy importantes en Argentina que confiaron en nuestros sistemas de monitoreo de energía,
                                    medición de energía, como así también medición e integración de variables de medición no eléctricas normalmente existentes en la industria.


                                </p>
                            </div>
                            <div className="col-lg-4">
                                <h4>Sector Utility  y mercado mayorista MEM.</h4>
                                <p>
                                    ABAMPERE se especializa en la construcción de proyectos SMEC para el mercado eléctrico mayorista MEM.
                                    Abampere ofrece la solución completa para este segmento: <br/>
                                    Tableros de medición SMEC<br/>
                                    Proyecto SMEC.<br/>
                                    Gestión ante CAMMESA y ENRE.<br/>
                                    Tableros para medición de Calidad de energía homologados por el ENRE.
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <h4>Soluciones para Edificios</h4>
                                <p>
                                    ABAMPERE cuenta con toda la línea de medidores para el segmento de building.
                                    Las mejores opciones de monitoreo de energía para edificios.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-no-margin justify-content-center bg-light" style={{marginBottom: 30}}>
                </div>
                <div className="row-no-margin justify-content-center bg-abp-extra-dark-blue">
                    <div className="fixed-size-column">
                        <div className="row-no-margin">
                            <div className="col-lg-5 col-md-6 text-white content-divider">
                                <h3>Sistemas de monitoreo de energía</h3>
                                <p>
                                    Un sistema de gestión de energía conducirá a una productividad mejorada y una
                                    reducción en los costos de energía. Los sistemas de gestión de energía de ABAMPERE
                                    se pueden integrar completamente con una gama de hardware de terceros para proporcionar
                                    una visión general completa del sistema.
                                </p>
                            </div>
                            <div className="col-lg-7 col-md-6" style={{ backgroundImage: `url(${image1})`, backgroundPosition: "center center", backgroundSize: "cover", padding: "0 0 0 0"}}>
                                {/*TRIANGULO IZQUIERDO*/}
                                <div className="row-no-margin">
                                    <div style={{width: 0,
                                        height: 0,
                                        borderStyle: "solid",
                                        borderWidth: "251px 100px 0 0",
                                        borderColor: "#323045 transparent transparent transparent"}}>
                                    </div>
                                    {/*TRIANGULO DERECHO*/}
                                    <div className="float-right" style={{
                                        position: "relative",
                                        marginLeft:"auto",
                                        marginRight:0,
                                        width: 0,
                                        height: 0,
                                        borderStyle: "solid",
                                        borderWidth: "0 0 251px 100px",
                                        borderColor: "transparent transparent #323045 transparent"}}>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-no-margin justify-content-center bg-light" style={{marginBottom: 30}}>
                </div>
                <div className="row-no-margin justify-content-center content-divider bg-abp-light-blue">
                    <div className="col-lg-8 col-md-10 white-text">
                        <h3> Vea nuestras demos online</h3>
                    </div>
                </div>
                <div className="row-no-margin justify-content-center">
                    <div className="col-lg-11 col-md-12">
                        <ClientsComponent />
                    </div>
                </div>
            </div>
        )
    }
}

export default HomeComponent;
