import React from "react";
import "../css/CalidadDeEnergia.css"
import image1 from "../img/products/998-20734276_Social-02_1200x630.jpg";
import vwLogo from "../img/vwlogo.png"
import CarouselPage from "./CarouselPage";

class CasoDeExitoIndividual extends React.Component{

    render(){
        let panel;

        if (this.props.panel){
            panel = <img className="d-block m-auto" src={this.props.panel}  style={{width: 300, height: 300, objectFit: "contain"}}/>
        } else {
            panel = <div></div>
        }
        return(
            <div style={{marginTop:10}}>
                <img className="d-block m-auto" src={this.props.img}  style={{width: 256, height: 256, objectFit: "contain"}}/>
                <h3 className="content-divider text-center" style={{color: "#413b6b", fontWeight: 700}}> { this.props.title } </h3>
                <p> {this.props.text}</p>
                { panel }
            </div>
        )
    }
}

export default CasoDeExitoIndividual;
