import React from "react";
import ClientsComponent from "./ClientsComponent";
import { library} from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faMapMarked, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

class FooterComponent extends React.Component{
    render() {
        return (
            <footer className="row-no-margin bg-abp-extra-dark-blue" style={{paddingTop: "20px", paddingBottom: "20px"}}>
                <div className="container">
                    <div className="justify-content-center text-center text-white-50">
                        <p> Av. Vergara 1989 B1688GEK Villa Tesei Hurlingham. <FontAwesomeIcon style={{marginLeft: 5}} icon={faMapMarked} /> </p>
                        <p> Tel: +54 11-7712-2617 <FontAwesomeIcon style={{marginLeft: 5}} icon={ faPhone} /></p>
                        <a>Email: ventas@abampere.com.ar <FontAwesomeIcon style={{marginLeft: 5}} icon={faEnvelope} /> </a>
                    </div>
                </div>
            </footer>
        )
    }
}

export default FooterComponent;
