import React from "react";
import schneider1 from "../img/nosotros/28059311_1777436582306991_4649054911981803618_n.png";
import schneider2 from "../img/nosotros/EcoXpert-Substation-Automation-Badge_white-backgorund_130218-300x300.png";
import mapaIntegradores from "../img/nosotros/7.png";
import vancouver1 from "../img/nosotros/vancouver1.png";
import vancouver2 from "../img/nosotros/vancouver2.jpg";
import premio from "../img/nosotros/premio.png";
import premioHongKong from "../img/nosotros/premio_hong_kong.png";
import img1 from "../img/cursos/image7.png"
import img2 from "../img/cursos/image2.png"
import img3 from "../img/cursos/image3.png"
import ReactGA from "react-ga";

class CursosComponent extends React.Component{

    render() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        return(
            <div>
                <div className="row justify-content-center"
                     style={{marginRight: "0px", marginLeft: "0px", backgroundColor: "#413b6b"}}>

                    <div className="row content-divider">
                        <div className="flex-column">
                            <h1 className="white-text"> Capacitaciones técnicas intermedias y avanzadas </h1>
                        </div>
                    </div>
                </div>
                <div className="row-no-margin justify-content-center bg-white" style={{
                    paddingTop: "50px",
                    paddingBottom: "50px",
                    fontSize: "18px"
                }}>
                    <div className="fixed-size-column">
                        <div className="row">
                            <h4 style={{marginBottom: 10}} className="text-center"><b>Capacitación Nivel intermedio de Sistema de Monitoreo de energía Power Monitoring Expert.</b></h4>
                            <div className="col-lg-6">
                                <ul>
                                    <li>Dashboards (Cuadros de mando)</li>
                                    <li>Tendencias</li>
                                    <li>Alarmas en Power Monitoring Expert</li>
                                    <li>Reportes web</li>
                                    <li>Customización de usuario</li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <img className="img-fluid" src={img1}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-no-margin justify-content-center content-divider" style={{
                    paddingTop: "50px",
                    paddingBottom: "50px",
                    fontSize: "18px"
                }} >
                    <div className="fixed-size-column">
                        <div className="row-no-margin">
                            <h4 style={{marginBottom: 10}} className="text-center">
                                <b>Capacitación Nivel Avanzado de sistema de Monitoreo de energía Power Monitoring Expert:</b>
                            </h4>
                            <div className="col-lg-6">
                                <img className="img-fluid" src={img2}/>
                            </div>
                            <div className="col-lg-6">
                                <ul>
                                    <li>Dashboards (Cuadros de mando)</li>
                                    <li>Tendencias</li>
                                    <li>Alarmas en Power Monitoring Expert</li>
                                    <li>Reportes web</li>
                                    <li>Customización de usuario</li>
                                    <li>Configuración de Reportes y Subscripciones</li>
                                    <li>Análisis Reporte de Calidad de Energía</li>
                                    <li>Configuración y vistas de Alarmero</li>
                                    <li>Funciones de Control desde la Web.</li>
                                    <li>Crear Diagrama en Vista con Control, y agregarlo en la Web.</li>
                                    <li>Personalización del PME (Temas, Tipos de Login, Contraseñas, Lenguaje y Región)</li>
                                    <li>Device Manager en la Web.</li>
                                    <li>Customizar un reporte.</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row-no-margin justify-content-center content-divider white-text" style={{
                    paddingTop: "50px",
                    paddingBottom: "50px",
                    fontSize: "18px",
                    backgroundColor: "#413b6b"
                }}
                >
                    <div className="fixed-size-column">
                        <div className="row-no-margin">
                            <h4 style={{marginBottom: 10}} >
                                <b>Capacitación avanzada de programación ION</b>
                            </h4>
                        </div>
                        <div className="row-no-margin">
                            <div className="col-lg-6">
                                <ul>
                                    <li>Introducción a Arquitectura ION.</li>
                                    <li>Calculo de Mínimos, Máximos y almacenamiento en DB.</li>
                                    <li>Maximum Module.</li>
                                    <li>Minimum Module.</li>
                                    <li>DataRecorder Module.</li>
                                    <li>Clock Module.</li>
                                    <li>Creación de Alarmas por Setpoint.</li>
                                    <li>Setpoint Module</li>
                                    <li>External Pulse Module.</li>
                                    <li>Arithmetic Module.</li>
                                    <li>Email Module.</li>
                                    <li>Data Monitor Module.</li>
                                    <li>Database Import Module.</li>
                                    <li>Store Module.</li>
                                    <li>Feedback Module.</li>
                                    <li>Arithmetic Module.</li>
                                    <li>Database Record Module.</li>
                                    <li>Ejemplo - Automatización Salidas de ION7400 y PM8000.</li>
                                    <li>External Boolean Module.</li>
                                    <li>Digital Output Module.</li>
                                    <li>Counter Module.</li>
                                    <li>Clock Module.</li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <img className="img-fluid" src={img3}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CursosComponent;
